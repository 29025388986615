export const HEADING = [
  {
    id: 1,
    desc: 'S/N',
  },
  {
    id: 2,
    desc: 'Firstname',
  },
  {
    id: 3,
    desc: 'Lastname',
  },
  {
    id: 4,
    desc: 'Username',
  },
  {
    id: 5,
    desc: 'Email',
  },
  {
    id: 6,
    desc: 'Gender',
  },
  {
    id: 7,
    desc: 'Action',
  },
];

export const HEADING_DEPARTMENTS = [
  {
    id: 1,
    desc: 'S/N',
  },
  {
    id: 2,
    desc: 'Department',
  },
  {
    id: 3,
    desc: 'Action',
  },
];

export const DETAILS = [
  {
    id: 1,
    user: 'User1',
    email: 'user1@gmail.com',
    date: '10/10/2020',
    time: '1 minute',
    status: 'published',
  },
  {
    id: 2,
    user: 'User2',
    email: 'user2@gmail.com',
    date: '10/10/2020',
    time: '2 minute',
    status: 'published',
  },
  {
    id: 3,
    user: 'User3',
    email: 'user3@gmail.com',
    date: '10/10/2020',
    time: '3 minute',
    status: 'unpublished',
  },
  {
    id: 4,
    user: 'User4',
    email: 'user4@gmail.com',
    date: '20/10/2020',
    time: '4 minute',
    status: 'unpublished',
  },
  {
    id: 5,
    user: 'User5',
    email: 'user5@gmail.com',
    date: '10/10/2020',
    time: '5 minute',
    status: 'published',
  },
  {
    id: 6,
    user: 'User6',
    email: 'user6@gmail.com',
    date: '10/10/2020',
    time: '6 minute',
    status: 'unpublished',
  },
];

export const MODAL = [
  {
    id: 1,
    desc: 'Edit',
    name: 'Edit',
  },
  {
    id: 2,
    desc: 'Delete',
    name: 'delete',
  },
];
